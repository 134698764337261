import {useContext} from "react"

import {LoginContext} from "../../context/loginContext"
import Button from "../Button"
import guarantee from "../../assets/icons/guarantee.svg"
import cancelAnytime from "../../assets/icons/cancelAnytime.svg"
import {PLATFORM_API} from "../../constants"
import useResize from "../../hooks/useResize"
import classes from "./index.module.scss"

const Learn = () => {
  const { width } = useResize()
  const {setLoginOpen} = useContext(LoginContext)

  // useEffect(() => {
  //   gsap.to(".parallax-learn", {
  //     scrollTrigger: {
  //       scrub: true
  //     },
  //     y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
  //     ease: "none"
  //   });
  // }, [])
  //
  // useEffect(() => {
  //   gsap.to(".learn-image", {
  //     scrollTrigger: {
  //       trigger: ".red",
  //       toggleActions: "restart none none reverse",
  //       // scrub: true,
  //       duration: .5,
  //       start: "top top",
  //     },
  //     marginBottom: -200
  //   })
  // })

  return (<div className={classes.container}>
    <h2 className={classes.header}>Learn to make
      {width <= 1100 && <br/>}
      <span className={classes.violet}> only right </span>
      {width > 1100 && width < 1200 && <br/>}
      decisions
    </h2>

    <span className={classes.about}>
      Our
      <span> educational platform and business simulator </span>
      has helped over 1,000 entrepreneurs achieve financial success in their businesses.
    </span>

    {width > 1100 && <Button type="violet" size="small" onClick={() => setLoginOpen(true)}>Get started</Button>}

    <div className={classes.charactersBlock}>
      <div>
        <img src={guarantee} alt="guarantee"/>
        <span className={classes.text}>100% Money Back Guarantee</span>
      </div>

      <div>
        <img src={cancelAnytime} alt="cancelAnytime"/>
        <span className={classes.text}>Cancel the subscription anytime</span>
      </div>
    </div>

    {width <= 1100 && <Button type="violet" size="full" link={PLATFORM_API}>Get started</Button>}

    {width > 1100 && <img data-speed=".15" width="352px" src="/images/learnLeftImage.png" className={`${classes.learnLeftImage} parallax-learn`} alt="learnLeftImage"/>}
    {width > 1100 && <img data-speed=".1" width="338px" src="/images/learnRightImage.png" className={`${classes.learnRightImage} parallax-learn`} alt="learnRightImage"/>}
    {width > 1100 && <img data-speed=".2" width="458px" src="/images/learnBottomImage.png" className={`${classes.learnBottomImage} parallax-learn`} alt="learnBottomImage"/>}
    {width <= 1100 && <img width="348px" src="/images/learnBottomImage.png" className={`${classes.learnBottomMobileImage} learn-image`} alt="learnBottomImage"/>}
  </div>)
}

export default Learn
