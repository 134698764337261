export const postDataToSheets = async (data) => {
  try {
    await fetch('https://sheet.best/api/sheets/2895249e-a3da-42df-8b51-4d83ea139c1d', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  } catch (err) {
    console.log(err)
  }
}

export const getDatas = () => {
  const res = fetch("https://ipapi.co/json").then(res => res.json())
  if(res){
    return res
  }
  const res1 = fetch("https://hutils.loxal.net/whois").then(res => res.json())
  return res1
}

export const isIpIncludes = async (ip, type) => {
  const res = await fetch('https://sheet.best/api/sheets/2895249e-a3da-42df-8b51-4d83ea139c1d').then(res => res.json())
  return res.find(r => r.ip === ip && r.type === type)
}
