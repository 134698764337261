import Slider from "react-slick"
import {defineElement} from "lord-icon-element"
import {loadAnimation} from "lottie-web"

import {reviews} from "../../constants/reviews"
import Rewiew from "../Review"
import SectionHeader from "../SectionHeader"
import classes from "./index.module.scss"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Rewiews = () => {
  defineElement(loadAnimation);

  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
         <lord-icon  colors="primary:#32284B" trigger="click" src="https://cdn.lordicon.com/iifryyua.json"/>
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
         <lord-icon  colors="primary:#32284B" style={{transform: "scaleX(-1)"}} trigger="click" src="https://cdn.lordicon.com/iifryyua.json"/>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
    ]
  };

  return(
    <div className={classes.container}>
      <SectionHeader text="Reviews" isCentered/>

      <Slider
        className={classes.insideContainer}
        {...settings}
      >
        {reviews.map((r) => (
          <Rewiew
            key={r.name}
            name={r.name}
            position={r.position}
            text={r.text}
            img={r.img}
            sign={r.sign}
          />
        ))}
      </Slider>
    </div>
  )
}

export default Rewiews
