import ReactModal from 'react-modal'
import {useEffect, useContext} from "react"

import {LoginContext} from "../../context/loginContext"
import close from "../../assets/icons/close.svg"
import headIcon from "../../assets/icons/headIcon.svg"
import {PLATFORM_API, GAME_API} from "../../constants"
import classes from "./index.module.scss"

const BurgerMenu = ({isOpen, setOpen}) => {
  const {setLoginOpen} = useContext(LoginContext)

  useEffect(() => {
    if(isOpen) {
      document.querySelector("body").style.overflow = "hidden"
    } else {
      document.querySelector("body").style["overflow-y"] = "auto"
    }
  }, [isOpen])

  return(
    <ReactModal
      isOpen={isOpen}
      className={classes.container}
      ariaHideApp={false}
    >
      <div className={classes.block}>
        <div className={classes.mainHead}>
          <div className={classes.headBlock}>
            <img src={headIcon} alt="Teachmegrow"/>
            <span className={classes.header}>
              <span className={classes.teach}>TEACH</span>
              <span className={classes.me}>ME</span>
              <span className={classes.grow}>GROW</span>
            </span>
          </div>

          <img src={close} alt="close" onClick={() => setOpen(false)}/>
        </div>

        <a href={PLATFORM_API} target="_blank" rel="noreferrer noopener">🎓 Learning Platform</a>

        <a href={GAME_API} target="_blank" rel="noreferrer noopener">🚀 Startup Simulator</a>

        <a href="/" >Contact us</a>
      </div>

      <button className={classes.start} onClick={() => { setLoginOpen(true); setOpen(false)}}>Login</button>
    </ReactModal>
  )
}

export default BurgerMenu
