export const whatLearn = [{
  name: "Unit economics",
  text: "Success in business is not about being lucky. It's about understanding the profit from each client and using that information to your advantage."
}, {
  name: "Profit driven marketing",
  text: "Our tried and tested method will equip you with the necessary skills and resources to make informed, profitable marketing choices."
}, {
  name: "Business scaling",
  text: "In a matter of months, you will be able to test any idea and transform it into a highly sought-after, scalable product or project."
}]
