import classes from "./index.module.scss"

const Button = ({type, size, link, onClick, children}) => {
  if (link) {
    return <a href={link} rel="noreferrer noopener" className={`${classes.button} ${classes[type]} ${classes[size]}`}>
      {children}
    </a>
  }
  return (
    <button className={`${classes.button} ${classes[type]} ${classes[size]}`} onClick={onClick}>
      {children}
    </button>
  )
}

export default Button
