import {useContext} from "react"

import {LoginContext} from "../../context/loginContext"
import goToPlatform from "../../assets/icons/goToPlatform.svg"
import goalTarget from "../../assets/images/goalsTarget.png"
import SectionHeader from "../SectionHeader"
import useResize from "../../hooks/useResize"
import classes from "./index.module.scss"

const WhyImportant = () => {
  const { width } = useResize()
    const {setLoginOpen} = useContext(LoginContext)

  return(
    <div className={classes.container}>
      <div className={classes.leftBlock}>
        <div className={classes.leftPart}>
          <SectionHeader text="Why is it important?"/>
          <div className={classes.leftPartData}>
            <div className={classes.leftPartTopBlock}>
              <span className={classes.percent}>80%</span>
              <span>of startups and online businesses fail. <br/> Leading cause - poor cash management.</span>
            </div>

            <div className={classes.leftPartBottomBlock}>
              <span>
                We combined all practical knowledge on this and other top mistakes, and presented it in the most interactive way.
              </span>
            </div>

          </div>
        </div>

        <div className={classes.rigthPart}>
          <img src={goalTarget} width="340px" alt="goal target"/>
        </div>
      </div>

      <div onClick={() => setLoginOpen(true)} className={classes.rightBlock}>
        <SectionHeader text="Try our platform" isWhite/>
        {width > 1100 ? <img src={goToPlatform} alt="goToPlatform"/> : <img src={goalTarget} width="150px" alt="goal target"/>}
      </div>
    </div>
  )
}

export default WhyImportant
