import { createContext, useState } from "react";

let LoginContext;
let { Provider } = (LoginContext = createContext());

let LoginProvider = ({ children }) => {
  const [loginOpen, setLoginOpen] = useState(false)

  return (
    <Provider value={{ loginOpen, setLoginOpen, LoginContext }}>
      {children}
    </Provider>
  );
};

export { LoginContext, LoginProvider };
