import {useContext, useState} from "react"

import {LoginContext} from "../../context/loginContext"
import {ReactComponent as Logo} from "../../assets/icons/logo.svg"
import {ReactComponent as LogoMob} from "../../assets/icons/logoMob.svg"
import openLogin from "../../assets/icons/openLogin.svg"
import Button from "../Button"
import BurgerMenu from "../BurgerMenu"
import useResize from "../../hooks/useResize"
import classes from "./index.module.scss"

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const {setLoginOpen} = useContext(LoginContext)
  const { width } = useResize()

  return(<div className={classes.container}>
    <div className={classes.leftBlock}>
      {width > 1100 ? <Logo/> : <LogoMob/>}
    </div>

    {width > 1100 ?
      <Button type="violet" size="mini" onClick={() => setLoginOpen(true)}>Login</Button> :
      <img src={openLogin} alt="open menu" onClick={() => setMenuOpen(true)}/>
    }

    <BurgerMenu setOpen={setMenuOpen} isOpen={menuOpen}/>
  </div>)
}

export default Header
