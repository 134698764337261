import {useState} from "react"

import SectionHeader from "../SectionHeader"
import {questions} from "../../constants/questions"
import QuestionBlock from "../QuestionBlock"
import classes from "./index.module.scss"

const Questions = () => {
  const [opened, setOpened] = useState(null)

  return(
    <div className={classes.container}>
      <SectionHeader text="Frequently Asked Questions" isCentered/>
      <div className={classes.questionsContainer}>
        {questions.map(q => <QuestionBlock key={q.name} text={q.text} name={q.name} setOpened={setOpened} isOpen={q.name === opened}/>)}
      </div>
    </div>
  )
}

export default Questions
