import Zuniga from "../assets/images/E. Zuniga.png"
import Thorton from "../assets/images/P. Thorton.png"
import Henry from "../assets/images/D. Henry.png"
import Bridges from "../assets/images/K. Bridges.png"
import Powell from "../assets/images/R. Powell.png"
import Marshall from "../assets/images/C. Marshall.png"
import Izak from "../assets/images/K. Izak.png"
import Greenwood from "../assets/images/L. Greenwood.png"
import Christian from "../assets/icons/Christian Marshall.svg"
import Darell from "../assets/icons/Darell Henry.svg"
import Emery from "../assets/icons/Emery Zuniga.svg"
import Kasza from "../assets/icons/Kasza Izsak.svg"
import Kaylah from "../assets/icons/Kaylah Bridges.svg"
import Leo from "../assets/icons/Leo Greenwood.svg"
import Pierre from "../assets/icons/Pierre Thorton.svg"
import Reina from "../assets/icons/Reina Powell.svg"

export const reviews = [
  {
    img: Zuniga,
    name: "Emery Zuniga ",
    text: "If you want to know what it takes to grow your business from a seedling to a mighty oak, TeachMeGrow is the program for you. With a simulation so realistic, you'll feel like you're running a real business (minus the sleepless nights and caffeine addiction).",
    sign: Emery
  },{
    img: Thorton,
    name: "Pierre Thorton",
    text: "I mean, I spent years in business school, but it wasn't until TeachMeGrow came along that I got my hands dirty and really understood the ins and outs of the financial concepts behind digital businesses.",
    sign: Pierre
  },{
    img: Henry,
    name: "Darell Henry",
    text: "The simulator provided me with an opportunity to run a start-up in a risk-free environment, which was an absolute blast. And that's not it, the education part helped me up my marketing game, leading to a 23% boost in conversion rates for my ecommerce shop. ",
    sign: Darell
  },{
    img: Bridges,
    name: "Kaylah Bridges",
    text: "I thought I was ready to take on the world with my killer start-up idea, but TeachMeGrow put me in my place (in the nicest way possible, of course). Turns out, I still had a lot to learn about market-sizing before diving headfirst into the start-up game. ",
    sign: Kaylah
  },{
    img: Powell,
    name: "Reina Powell",
    text: "TeachMeGrow isn't just a program - it's a course in how to be a CEO! Not only did I get to play around with unit-economics (which is way cooler than it sounds), but I also learned how to think like a business owner. ",
    sign: Reina
  },{
    img: Marshall,
    name: "Christian Marshall",
    text: "This program gives you the inside scoop on every stage of the startup journey, from ideation to funding and all the financial wizardry in between. The simulation is so engaging and realistic, you'll feel like you're living and breathing the startup life.",
    sign: Christian
  },{
    img: Izak,
    name: "Kasza Izsak",
    text: "This program is perfect for any student who wants to level up their fundamental financial skills. You will learn how to manage a team, make tough decisions, and grow your business to the max. ",
    sign: Kasza
  },{
    img: Greenwood,
    name: "Leo Greenwood",
    text: "I gotta tell you about this course - it was like a masterclass in unit-economics wizardry! The way it was designed made it super easy to wrap my head around even the most complicated concepts, and the business cases? Let's just say they were more engaging than a cat video on YouTube.",
    sign: Leo
  },
]
