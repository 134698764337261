import SectionHeader from "../SectionHeader"
import {benefits} from "../../constants/pricing"
import leftPrice from "../../assets/icons/leftPrice.svg"
import blackLine from "../../assets/icons/blackLine.svg"
import blueLine from "../../assets/icons/blueLine.svg"
import classes from "./index.module.scss"
import {useContext} from "react";
import {LoginContext} from "../../context/loginContext";

const Pricing = () => {
  const {setLoginOpen} = useContext(LoginContext)

  return(<div className={classes.container}>
    <SectionHeader text="Pricing plans" isCentered/>

    <div className={classes.accessCardsContainer}>
      <div className={`${classes.accessCard} ${classes.accessCardRight}`}>
        <h4>Monthly</h4>

        <span className={classes.accessCardText}>Learn as you go. <br/> Open access to hours of <br/> business knowledge.</span>

        <div className={classes.accessPricesBlock}>
          <img src={blackLine} alt="line"/>
          <span className={classes.accessFirstPrice}>$299</span>
          <span className={classes.accessSecondPrice}>$199</span>
          <span className={classes.accessPriceOnDate}>/month</span>
        </div>

        <div className={classes.accessBenefitsContainer}>{benefits.V1
          .map(b => <div className={classes.accessBenefit} key={b}>
            <img src={leftPrice} alt="benefit"/>
            {b}
          </div>)
        }
        </div>

        <button className={`${classes.accessButton} ${classes.accessBlueButton}`} onClick={() => setLoginOpen(true)}>Choose plan</button>
      </div>

      <div className={`${classes.accessCard} ${classes.accessCardLeft}`}>
        <h4>Lifetime access</h4>

        <span className={classes.accessCardText}>Keep access forever. <br/> Try hypotheses and strategies that <br/> fit your business the most.</span>

        <div className={classes.accessPricesBlock}>
          <img src={blueLine} alt="line"/>
          <span className={classes.accessFirstPrice}>$799</span>
          <span className={classes.accessSecondPrice}>$499</span>
        </div>

        <div className={classes.accessBenefitsContainer}>{benefits.V2
          .map(b => <div className={classes.accessBenefit} key={b}>
            <img src={leftPrice} alt="benefit"/>
            {b}
          </div>)
        }
        </div>

        <button className={`${classes.accessButton} ${classes.accessGreenButton}`} onClick={() => setLoginOpen(true)}>Choose plan</button>
      </div>
    </div>
  </div>)
}

export default Pricing
