import {useContext} from "react"

import {LoginContext} from "../../context/loginContext"
import SectionHeader from "../SectionHeader"
import Button from "../Button"
import learnHowToBuildLeftRocket from "../../assets/icons/learnHowToBuildLeftRocket.svg"
import learnHowToBuildRightRocket from "../../assets/icons/learnHowToBuildRightRocket.svg"
import {GAME_API} from "../../constants"
import useResize from "../../hooks/useResize"
import classes from "./index.module.scss"

const LearnHowToBuild = () => {
  const { width } = useResize()
  const {setLoginOpen} = useContext(LoginContext)

  return (
    <div className={classes.container}>
      <img src={learnHowToBuildLeftRocket} alt="LearnHowToBuildLeftRocket" className={classes.learnHowToBuildLeftRocket}/>
      <div className={classes.insideContainer}>
        {width > 1100 ?
          <SectionHeader text="Running an eccomerce shop?" isWhite/> :
          <h3 className={classes.header}>Running <br/> an ecommerce <br/> store?</h3>
        }

        <Button size={width > 1100 ? "middle" : "full"} type="white" onClick={() => setLoginOpen(true)}>Start simulator</Button>
      </div>
      {width > 1100 && <img src={learnHowToBuildRightRocket} alt="LearnHowToBuildRightRocket" className={classes.learnHowToBuildRightRocket}/>}
    </div>
  )
}

export default LearnHowToBuild
