import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ToastContainer } from 'react-toastify'
import {useContext} from "react"

import Header from "../../components/Header"
import Learn from "../../components/Learn"
import Questions from "../../components/Questions"
import WhatLearn from "../../components/WhatLearn"
import HowLearn from "../../components/HowLearn"
import WhoNeeds from "../../components/WhoNeeds"
import WhyImportant from "../../components/WhyImportant"
import Pricing from "../../components/Pricing"
import LearnHowToBuild from "../../components/LearnHowToBuild"
import Rewiews from "../../components/Rewiews"
import ContactUs from "../../components/ContactUs"
import Login from "../../components/Login"
import {LoginContext} from "../../context/loginContext"
import classes from "./index.module.scss"
import 'react-toastify/dist/ReactToastify.css'

gsap.registerPlugin(ScrollTrigger);

const Main = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });

  const {loginOpen, setLoginOpen} = useContext(LoginContext)

  return (<div className={classes.container}>
    <Header/>
    <Learn/>
    <WhyImportant/>
    <WhatLearn/>
    <HowLearn/>
    <WhoNeeds/>
    <LearnHowToBuild/>
    <Rewiews/>
    <Questions/>
    <Pricing/>
    <ContactUs/>
    <Login isOpen={loginOpen} setOpen={setLoginOpen}/>
    <ToastContainer />
    <footer>Footer</footer>
  </div>)
}

export default Main
