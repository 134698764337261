import {useState} from "react"
import { DateTime } from "luxon"
import {useLocation} from "react-router-dom"
// import gsap from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"

import SectionHeader from "../SectionHeader"
import {whatLearn} from "../../constants/whatLearn"
import useResize from "../../hooks/useResize"
// import whatLearnBottom from "../../assets/images/whatLearnBottom.png"
import whatLearnImage from "../../assets/images/whatLearn.png"
import whatLearnSecondPage from "../../assets/images/whatLearnSecondPage.png"
import download from "../../assets/icons/download.svg"
import doc from "../../assets/icons/doc.svg"
// import whatLearnTop from "../../assets/images/whatLearnTop.png"
// import whatLearnTop from "../../assets/icons/whatLearnTop.svg"
import {postDataToSheets, getDatas, isIpIncludes} from "../../helpers"
import classes from "./index.module.scss"

const WhatLearn = () => {
  const [isProgramPage, setIsProgramPage] = useState(false)
  const [email, setEmail] = useState("")
  const [emailValid, setEmailValid] = useState(false)

  const location = useLocation()?.search
  const funnel = new URLSearchParams(location).get("funnel")

  const { width } = useResize()

  // useEffect(() => {
  //   gsap.to(".parallax-what-learn", {
  //     scrollTrigger: {
  //       scrub: true
  //     },
  //     y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
  //     ease: "none"
  //   });
  // }, [])

  const handleChangeInput = (e) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
    setEmail(e.target.value)
    setEmailValid(regex.test(e.target.value))
  }

  const handleDownload = async (e) => {
    if(!emailValid) {
      return e.preventDefault()
    }

    const date = DateTime.now().setZone("UTC+2").toFormat("dd/LL/yyyy HH:mm:ss")
    const sendData = await getDatas()
    const ipIncludes = await isIpIncludes(sendData?.ip, "download")

    !ipIncludes && await postDataToSheets({
      source: funnel,
      date,
      email: email,
      ip: sendData?.ip,
      city: sendData?.city,
      stateprov: sendData?.region || "-",
      country_name: sendData?.country_name || sendData?.country,
      type: "download"
    })
  }

  return(
    <div className={isProgramPage ? classes.programContainer : classes.container}>
      {isProgramPage ?
        <>
          <div className={classes.leftPart}>
            <SectionHeader text="Full learning program"/>

            <span>Leave your email now and download a PDF with the detailed learning program. We promise not to spam! 🤝</span>

            <div className={classes.dataBlock}>
              <input placeholder="Email" type="email" value={email} onChange={handleChangeInput}/>

              <a
                download
                className={`${classes.download} ${emailValid ? classes.openedLink : classes.disabledLink}`}
                onClick={handleDownload}
                target="_blank"
                rel="noreferrer noopener"
                href="/TeachMeGrow - Learning Program.pdf"
              >
                <img src={download} alt="download" />
                Download learning program
              </a>

              <button onClick={() => setIsProgramPage(false)} className={classes.backButton}>
                Go Back
              </button>
            </div>
          </div>

          {width > 1100 && <div className={classes.image}/>}
        </> :
        <>
          <SectionHeader text="What you’ll learn"/>

          <div className={classes.containerItems}>
            {whatLearn.map(i => <div className={classes.item} key={i.name}>
              <h4>{i.name}</h4>
              <span>{i.text}</span>
            </div>)}
          </div>

          {/*{width > 1100 && <img data-speed=".05" src={whatLearnTop} alt="whatLearnTop" className={`${classes.whatLearnTop} parallax-what-learn`}/>}*/}
          {width > 1100 && <img data-speed=".15" src={whatLearnImage} alt="whatLearn"
                                className={`${classes.whatLearnImage} parallax-what-learn`}/>}
          <button className={classes.doc} onClick={() => setIsProgramPage(true)}>
            <img src={doc} alt="document"/>
            Check out the full program
          </button>
        </>
      }
    </div>
  )
}

export default WhatLearn
