import ReactModal from 'react-modal'
import {useState, useEffect} from "react"
import {useLocation} from "react-router-dom"
import { toast } from 'react-toastify'
import { DateTime } from "luxon"
import {useCookies} from "react-cookie"

import {postDataToSheets, getDatas, isIpIncludes} from "../../helpers"
import {PLATFORM_API} from "../../constants"
import headIcon from "../../assets/icons/headIcon.svg"
import close from "../../assets/icons/close.svg"
import facebook from "../../assets/images/facebook.png"
import google from "../../assets/images/google.png"
import loginLeft from "../../assets/images/loginLeft.png"
import loginRight from "../../assets/images/loginRight.png"
import arrowLeft from "../../assets/icons/arrowLeft.svg"
import { API_URL } from "../../constants"
import classes from "./index.module.scss"
import "./index.scss"

const Login = ({isOpen, setOpen}) => {
  const [email, setEmail] = useState({ value: "", valid: false })
  const [password, setPassword] = useState("")
  const [sended, setSended] = useState(false)
  const [userType, setUserType] = useState(null) //registered || new
  const [cookies, setCookies] = useCookies();

  const location = useLocation()?.search
  const funnel = new URLSearchParams(location).get("funnel")

  useEffect(() => {
    if(funnel) {
      setCookies("source", funnel, {domain: ".teachmegrow.com"})
    }
  }, [funnel])

  useEffect(() => {
    if(isOpen) {
      document.querySelector("body").style.overflow = "hidden"
    } else {
      document.querySelector("body").style["overflow-y"] = "auto"
    }
  }, [isOpen])

  const handleSetEmail = (e) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; // eslint-disable-line
    setEmail({value: e.target.value, valid: regex.test(e.target.value)})
  }

  const handleSetPassword = (e) => {
    setPassword(e.target.value)
  }

  const handleForgotPassword = () => {
    console.log("forgot")
  }

  const handleCheckEmail = async () => {
    setSended(true)
    const res = await fetch(`${API_URL}auth/check-email`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ "email": email.value })})

      if(res.status === 201) {
        return setUserType("new")
      }

      if(res.status === 409) {
        return setUserType('registered')
      }

      return toast.error("Something was wrong");
  }

  const handleBack = () => {
    setPassword("")
    setEmail({ value: "", valid: false })
    setSended(false)
    setUserType(null)
  }

  const handleStart = async () => {
    if (userType === 'new') {
      const res = await fetch(`${API_URL}auth/register/local`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ "email": email.value, "password": password })})

      if(res.status !== 201) {
        return toast.error("Something was wrong");
      }

      window.fbq('track', 'Lead')

      const date = DateTime.now().setZone("UTC+2").toFormat("dd/LL/yyyy HH:mm:ss")

      const sendData = await getDatas()

      const ipIncludes = await isIpIncludes(sendData?.ip, "registration")

      !ipIncludes && await postDataToSheets({
        source: funnel,
        date,
        email: email?.value,
        ip: sendData?.ip,
        city: sendData?.city,
        stateprov: sendData?.region || "-",
        country_name: sendData?.country_name || sendData?.country,
        type: "registration"
      })

      const login = await fetch(`${API_URL}auth/login/local`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ "email": email.value, "password": password })})

      if(login.status !== 201) {
        return toast.error("Something was wrong");
      }

      if(login.status === 201) {
        window.location = PLATFORM_API;
      }
    }

    if (userType === 'registered') {
      const login = await fetch(`${API_URL}auth/login/local`, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({ "email": email.value, "password": password })
      })

      if(login.status === 201) {
        window.location = PLATFORM_API;
      }

      if(login.status === 401) {
        return toast.error("Password was wrong");
      }
    }
  }

  const emailStyles = email.value ?
    (email.valid ?
      classes.success :
      classes.error) :
    ""

  return(
    <ReactModal
      isOpen={isOpen}
      className={classes.container}
      ariaHideApp={false}
    >
      <div className={classes.headContainer}>
        <div className={classes.mainHead}>
          <div className={classes.headBlock}>
            <img src={headIcon} alt="Teachmegrow"/>
            <span className={classes.header}>
              <span className={classes.teach}>TEACH</span>
              <span className={classes.me}>ME</span>
              <span className={classes.grow}>GROW</span>
            </span>
          </div>

          <img src={close} alt="close" onClick={() => setOpen(false)}/>
        </div>


        {!userType ? <h2 className={classes.headerName}>Learn to make <br/>
          <span className={classes.violet}> only right </span>
          decisions
        </h2> :
          userType === "new" ?
            <h2 className={classes.welcome}>
              <img src={arrowLeft} alt="go back" onClick={handleBack}/>
              Welcome!
            </h2> :
            <h2 className={classes.welcome}>
              <img src={arrowLeft} alt="go back" onClick={handleBack}/>
              Welcome back!
            </h2>
        }
      </div>

      {!userType && <div className={classes.inputsContainer}>
        <div className={classes.socialsContainer}>
          <form action={`${API_URL}auth/login/facebook/landing`} method="get">
            <button className={classes.socialButton}>
              <img src={facebook} alt="facebook" width={18}/>
              <span>Continue with Facebook</span>
            </button>
          </form>

          <form action={`${API_URL}auth/login/google/landing`} method="get">
            <button className={classes.socialButton}>
              <img src={google} alt="google" width={18}/>
              <span>Continue with Google</span>
            </button>
          </form>
        </div>

        <div className={classes.line}>
          <hr/>
          <span>or continue using email</span>
          <hr/>
        </div>

        <div className={classes.mainInputsContainer}>
          <input
            className={`${classes.email} ${sended ? emailStyles : ""}`}
            value={email.value}
            type="email"
            onChange={handleSetEmail}
            placeholder="Enter email"
          />

          <button className={classes.start} onClick={handleCheckEmail} disabled={!email.valid}>Start learning</button>
        </div>
      </div>}

      {userType === "new" && <div className={classes.inputsContainer}>
        <div className={classes.line}>
          <hr/>
          <span>example@example.com</span>
          <hr/>
        </div>

        <div className={classes.mainInputsContainer}>
          <input
            className={classes.email}
            value={password}
            type="text"
            onChange={handleSetPassword}
            placeholder="Create password"
          />

          <button className={classes.start} onClick={handleStart} disabled={password.length < 6}>Start learning</button>

          <span className={classes.links}>
            by continuing forward you agree to <br/>
            <a href="/" target="_blank" rel="noreferrer noopener">Privacy Policy</a> & <a href="/" target="_blank" rel="noreferrer noopener">Terms of Use</a>
          </span>
        </div>
      </div>}

      {userType === "registered" && <div className={classes.inputsContainer}>
        <div className={classes.line}>
          <hr/>
          <span>example@example.com</span>
          <hr/>
        </div>

        <div className={classes.mainInputsContainer}>
          <input
            className={classes.email}
            value={password}
            type="text"
            onChange={handleSetPassword}
            placeholder="Enter password"
          />

          <button className={classes.start} onClick={handleStart} disabled={password.length < 6}>Start learning</button>

          <span className={classes.forgot} onClick={handleForgotPassword}>
            Forgot password
          </span>
        </div>
      </div>}

      <img src={loginLeft} alt="login" className={classes.loginLeft}/>
      <img src={loginRight} alt="login" className={classes.loginRight}/>
    </ReactModal>
  )
}

export default Login
