import {useState} from "react"
// import gsap from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"
import {useLocation} from "react-router-dom"
import { DateTime } from "luxon"
import { toast } from 'react-toastify'

import {postDataToSheets, getDatas, isIpIncludes} from "../../helpers"
import SectionHeader from "../SectionHeader"
import Button from "../Button"
// import ContactUsBottom from "../../assets/images/ContactUsBottom.png"
import ContactUsBottom from "../../assets/icons/contactUsBottom.svg"
import ContactUsTop from "../../assets/images/ContactUsTop.png"
import useResize from "../../hooks/useResize"
import classes from "./index.module.scss"

const ContactUs = () => {
  const [email, setEmail] = useState("")
  const [question, setQuestion] = useState("")
  const { width } = useResize()

  const location = useLocation()?.search
  const funnel = new URLSearchParams(location).get("funnel")

  // useEffect(() => {
  //   gsap.to(".parallax-contact-us", {
  //     scrollTrigger: {
  //       trigger: "#contact-us",
  //       scrub: true
  //     },
  //     y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
  //     ease: "none"
  //   });
  // }, [])

  const handleSend = async () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if(!regex.test(email)) {
      return toast.error("Please enter a valid email address")
    }

    if(question.length < 7) {
      return toast.error("Question must be longer than 6 symbols")
    }

    const date = DateTime.now().setZone("UTC+2").toFormat("dd/LL/yyyy HH:mm:ss")

    const sendData = await getDatas()

    await postDataToSheets({
      source: funnel,
      date,
      email: email,
      ip: sendData?.ip,
      city: sendData?.city,
      stateprov: sendData?.region || "-",
      country_name: sendData?.country_name || sendData?.country,
      question,
      type: "question"
    }).then(() => toast.success("Your question has been sent successfully!"))
  }

  return(
    <div className={classes.container} id="contact-us">
      <div className={classes.leftPart}>
        <SectionHeader isWhite text="Still got questions? We’ve got the answers"/>
        {width > 1100 ?
          <span>We will gladly answer to any of your questions regarding our <br/> platform. Don’t hesitate to drop us a message.</span> :
          <span>We will gladly answer to any of your questions regarding our platform. Don’t hesitate to drop us a message.</span>
        }
      </div>

      <div className={classes.rightPart}>
        <input type="email" placeholder="Your email address" value={email} onChange={e => setEmail(e.target.value)}/>
        <textarea rows={2} placeholder="Your question" value={question} onChange={e => setQuestion(e.target.value)}/>
        <Button size="full" onClick={handleSend}>Send message</Button>
      </div>

      {width > 1100 && <img data-speed=".1" src={ContactUsTop} alt="Contact Us" className={`${classes.contactUsTopImage} parallax-contact-us`}/>}

      {width > 1100 && <img data-speed=".3" src={ContactUsBottom} alt="Contact Us" className={`${classes.contactUsBottomImage} parallax-contact-us`}/>}
    </div>
  )
}

export default ContactUs
