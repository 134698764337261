import {useEffect, useRef} from "react"

import useResize from "../../hooks/useResize"
import SectionHeader from "../SectionHeader"
import productManager from "../../assets/images/pm.png"
import entrepreneurs from "../../assets/images/oe.png"
import startupFounder from "../../assets/images/sf.png"
import marketing from "../../assets/images/marketing.png"
import topCircle from "../../assets/images/topCircle.png"
import middleCircle from "../../assets/images/middleCircle.png"
import bottomCircle from "../../assets/images/bottomCircle.png"
import {ReactComponent as BottomRocket} from "../../assets/icons/bottomRocket.svg"
import {ReactComponent as TopRocket} from "../../assets/icons/topRocket.svg"
import {ReactComponent as CenterRocket} from "../../assets/icons/centerRocket.svg"
import classes from "./index.module.scss"

const WhoNeeds = () => {
  const ref = useRef(null)
  const { width } = useResize()

  function getCenter(element) {
    const {left, top, width, height} = element.getBoundingClientRect();
    return {x: left + width / 2, y: top + height / 2}
}

  const handleMove = ({clientX, clientY}) => {
    let rocket  = ref.current
    const arrowCenter = getCenter(rocket);
    const radians = Math.atan2(clientY - arrowCenter.y, clientX - arrowCenter.x);
    const degree = 2 - (radians * (180 / Math.PI) * -1) + 90;
    rocket.style.transform = `translateX(-50%) rotate(${degree}deg)`;
  }

  useEffect(() => {
    if(!ref.current) {
      return
    }

    document.addEventListener("mousemove", handleMove)
    return () => {
      document.removeEventListener("mousemove", handleMove)
    }
  }, [handleMove, ref.current])

  return( <div className={classes.container}>
    <SectionHeader text="Who needs it" isCentered/>

    <div className={classes.dataBlock}>
      {width > 1100 && <img src={topCircle} alt="topCircle" className={classes.topCircle}/>}
      {width > 1100 && <img src={middleCircle} alt="middleCircle" className={classes.middleCircle}/>}
      {width > 1100 && <img src={bottomCircle} alt="bottomCircle" className={classes.bottomCircle}/>}

      <div className={classes.contentBlock}>
        <div className={`${classes.person} ${classes.firstPerson}`}>
          <div className={classes.personMain}>
            <img src={productManager} alt="Product managers"/>
            <span className={classes.position}>Product managers</span>
            {width > 1100 && <span className={classes.hoverMe}>Hover me</span>}
          </div>

          <ul className={classes.text}>
            Gain immediate benefits:
            <li>Tasks prioritization techniques (backlog refinement)</li>
            <li>Product metrics evaluation (LTV, ARPU, AVP)</li>
            <li>Financial justification of new features</li>
          </ul>
        </div>

        <div className={`${classes.person} ${classes.secondPerson}`}>
          <div className={classes.personMain}>
            <img src={entrepreneurs} alt="Entrepreneurs"/>
            <span className={classes.position}>Online entrepreneurs</span>
            {width > 1100 && <span className={classes.hoverMe}>Hover me</span>}
          </div>

          <ul className={classes.text}>
            Gain immediate benefits:
            <li>Business model validation</li>
            <li>Growth points identification (finding financial bottlenecks)</li>
            <li>Profit-oriented business management</li>
          </ul>
        </div>

        <div className={`${classes.person} ${classes.thirdPerson}`}>
          <div className={classes.personMain}>
            <img src={startupFounder} alt="Startup founders"/>
            <span className={classes.position}>Startup founders</span>
            {width > 1100 && <span className={classes.hoverMe}>Hover me</span>}
          </div>

          <ul className={classes.text}>
            Gain immediate benefits:
            <li>Evaluation of business model profitability (choosing optimal for your product type)</li>
            <li>Formulating strategic hypothesis</li>
            <li>Securing funding (preparing P&L’s)</li>
          </ul>
        </div>

        <div className={`${classes.person} ${classes.lastPerson}`}>
          <div className={classes.personMain}>
            <img src={marketing} alt="Marketing managers"/>
            <span className={classes.position}>Marketing managers</span>
            {width > 1100 && <span className={classes.hoverMe}>Hover me</span>}
          </div>

          <ul className={classes.text}>
            Gain immediate benefits:
            <li>Marketing campaign optimization</li>
            <li>Customer cohort analysis</li>
            <li>Acquisition channels selection</li>
          </ul>
        </div>

        {width > 1100 && <div className={classes.whoNeedsRocket} ref={ref}>
          <TopRocket/>
          <CenterRocket className={classes.rocketCenter}/>
          <BottomRocket className={classes.rocketBottom}/>
        </div>}
      </div>
    </div>
  </div>)
}

export default WhoNeeds
