import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom"
import Main from "./pages/Main";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Main/>}>
    </Route>
  )
)

export default router
