import classes from "./index.module.scss"

const Review = ({img, name, position, sign, text}) => {
  return (
    <div className={classes.container}>
      <div className={classes.insideContainer}>
        <img src={img} alt="person"/>
        <div>
          <span className={classes.name}>{name}</span>
          {position && <span className={classes.position}>{position}</span>}
        </div>
      </div>

      <span className={classes.text}>{text}</span>

      <img src={sign} alt="sign" className={classes.sign}/>
    </div>
  )
}

export default Review
