import {useState, useEffect} from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import useResize from "../../hooks/useResize"
import SectionHeader from "../SectionHeader"
import platform from "../../assets/icons/platform.svg"
import simulator from "../../assets/icons/simulator.svg"
import platformMobile from "../../assets/images/platformMobile.png"
import simulatorMobile from "../../assets/images/simulatorMobile.png"
import leftImage from "../../assets/icons/platform-simulatorLeft.svg"
import rightImage from "../../assets/icons/platform-simulatorRight.svg"
import topImage from "../../assets/icons/platform-simulatorTop.svg"
import classes from "./index.module.scss"

const HowLearn = () => {
  const [isSimulator, setIsSimulator] = useState(false)

  const { width } = useResize()

  // useEffect(() => {
  //   gsap.to(".parallax-how-learn", {
  //     scrollTrigger: {
  //       scrub: true
  //     },
  //     y: (i, target) => -ScrollTrigger.maxScroll(window) * target.dataset.speed,
  //     ease: "none"
  //   });
  // }, [])

  if(width <= 1100) {
    return <div className={classes.mobileContainer}>
       <SectionHeader text="How you’ll learn" isCentered/>

      <div className={classes.mobileImage}>
        <img src={isSimulator ? simulatorMobile : platformMobile} alt={isSimulator ? "simulator" : "platform"}/>
      </div>

      <div className={classes.typesBlock}>
        <div onClick={() => setIsSimulator(false)} className={`${classes.type} ${isSimulator ? classes.nonActive : classes.active}`}>
          <span>🎓 Learning Platform</span>
          <hr/>
        </div>

        <div onClick={() => setIsSimulator(true)} className={`${classes.type} ${isSimulator ? classes.active : classes.nonActive}`}>
          <span>🚀 Startup Simulator</span>
          <hr/>
        </div>
      </div>

      <div className={classes.textBlock}>
        {!isSimulator && <span className={classes.text}>Our platform is built to be the ultimate learning environment specifically for the material of this course, featuring interactive content, dynamic quizzes, vivid visuals, and much more. It is designed to be flexible and self-paced, so you can fit your studies around a busy schedule. Whether you're a seasoned business owner or a new entrepreneur, this is the perfect place to develop the knowledge and skills you need to succeed.</span>}
        {isSimulator && <span className={classes.text}>Startup Simulator is the centerpiece of our platform, that enables you to apply everything you've learned by virtually running a company. Designed to be used in parallel with the educational content, it's as close to the real-life experience of managing and growing a company as you can get, with hundreds of variables and room for unexpected twists and turns.</span>}
      </div>

      {isSimulator ? <div className={`${classes.topDataBlock} ${classes.topDataBlockRow}`}>
        <div className={`${classes.dataBlock} ${classes.smallBlock}`}>
          <span className={classes.dataBlockValue}>14124</span>
          <span className={classes.dataBlockText}>games currently have been played <br/> in the simulator</span>
        </div>

        <div className={`${classes.dataBlock} ${classes.smallBlock}`}>
          <span className={classes.dataBlockValue}>10</span>
          <span className={classes.dataBlockText}>distinct rounds with unique goals <br/> and learning objectives</span>
        </div>

        <div className={`${classes.dataBlock} ${classes.smallBottomBlock}`}>
          <span className={classes.dataBlockValue}>150</span>
          <span className={classes.dataBlockText}>moves holds the record for the <br/> fastest IPO achievements</span>
        </div>
      </div> : <div className={classes.topDataBlock}>
        <div className={`${classes.dataBlock} ${classes.smallBottomBlock}`}>
          <span className={classes.dataBlockValue}>> 1K entrepreneurs</span>
          <span className={classes.dataBlockText}>enrolled and rolled their business with us</span>
        </div>

        <div className={`${classes.dataBlock} ${classes.smallBottomBlock}`}>
          <span className={classes.dataBlockValue}>25+</span>
          <span className={classes.dataBlockText}>interactive learning modules <br/> with engaging content</span>
        </div>
      </div>}
    </div>
  }

  return(
    <div className={classes.mainContainer}>
      <SectionHeader text="How you’ll learn" isCentered/>
      <div className={classes.container}>
        {/*<img data-speed=".1" src={topImage} alt="topImage" className={`${classes.topImage} parallax-how-learn`}/>*/}
        <img data-speed=".15" src={leftImage} alt="leftImage" className={`${classes.leftImage} parallax-how-learn`}/>
        <img data-speed=".05" src={rightImage} alt="rightImage" className={`${classes.rightImage} parallax-how-learn`}/>
        <div className={classes.topBlock}>
          <div className={classes.topImagesBlock}>
            <img src={isSimulator ? simulator : platform} alt={isSimulator ? "simulator" : "platform"}/>
          </div>

          {isSimulator ? <div className={classes.topDataBlock}>
            <div className={classes.dataBlock}>
              <span className={classes.dataBlockValue}>14124</span>
              <span className={classes.dataBlockText}>games currently have been played <br/> in the simulator</span>
            </div>

            <div className={classes.dataBlock}>
              <span className={classes.dataBlockValue}>10</span>
              <span className={classes.dataBlockText}>distinct rounds with unique goals <br/> and learning objectives</span>
            </div>

            <div className={classes.dataBlock}>
              <span className={classes.dataBlockValue}>150</span>
              <span className={classes.dataBlockText}>moves holds the record for the <br/> fastest IPO achievements</span>
            </div>
          </div> : <div className={classes.topDataBlock}>
            <div className={classes.dataBlock}>
              <span className={classes.dataBlockValue}>> 1K entrepreneurs</span>
              <span className={classes.dataBlockText}>enrolled and rolled their business with us</span>
            </div>

            <div className={classes.dataBlock}>
              <span className={classes.dataBlockValue}>25+</span>
              <span className={classes.dataBlockText}>interactive learning modules <br/> with engaging content</span>
            </div>
          </div>}
        </div>

        <div className={classes.bottomBlock}>
          <div className={classes.typesBlock}>
            <div onClick={() => setIsSimulator(false)} className={`${classes.type} ${isSimulator ? classes.nonActive : classes.active}`}>
              <span>🎓 Learning Platform</span>
              <hr/>
            </div>

            <div onClick={() => setIsSimulator(true)} className={`${classes.type} ${isSimulator ? classes.active : classes.nonActive}`}>
              <span>🚀 Startup Simulator</span>
              <hr/>
            </div>
          </div>

          <div className={classes.textBlock}>
            {!isSimulator && <span className={classes.text}>Our platform is built to be the ultimate learning environment specifically for the material of this course, featuring interactive content, dynamic quizzes, vivid visuals, and much more. It is designed to be flexible and self-paced, so you can fit your studies around a busy schedule. Whether you're a seasoned business owner or a new entrepreneur, this is the perfect place to develop the knowledge and skills you need to succeed.</span>}
            {isSimulator && <span className={classes.text}>Startup Simulator is the centerpiece of our platform, that enables you to apply everything you've learned by virtually running a company. Designed to be used in parallel with the educational content, it's as close to the real-life experience of managing and growing a company as you can get, with hundreds of variables and room for unexpected twists and turns.</span>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowLearn
