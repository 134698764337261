import { Collapse } from 'react-collapse'

import questionRocket from "../../assets/icons/questionRocket.svg"
import minus from "../../assets/icons/minus.svg"
import plus from "../../assets/icons/plus.svg"
import useResize from "../../hooks/useResize"
import classes from "./index.module.scss"

const QuestionBlock = ({isOpen, name, text, setOpened}) => {
  const { width } = useResize()

  const handleSetOpened = () => {
    isOpen ? setOpened(false) : setOpened(name)
  }

  return (
    <div className={`${classes.container} ${!isOpen ? classes.hidedContainer : ""}`} onClick={handleSetOpened}>
      {width > 1100 ?
        <h4 className={classes.header}>{name}</h4> :
        <div className={classes.headerBlock}>
          <h4 className={classes.header}>{name}</h4>
          <img src={isOpen ? minus : plus} alt={isOpen ? "close" : "open"}/>
        </div>
      }
      <Collapse isOpened={isOpen} timeout="auto" unmountOnExit>
        <span className={classes.text}>{text}</span>
        <img src={questionRocket} alt="Rocket" className={isOpen ? classes.rocket : classes.hided}/>
      </Collapse>
    </div>
  )
}

export default QuestionBlock
